import React, { useEffect, useState } from 'react'
import amex from "./images/amex.png"
import logo from "./images/logo.png"
import master from "./images/master.png"
import visa from "./images/visa.png"
import { base_url, stripe_plans } from "../../api";

import "./style.css"
import axios from 'axios'
import Payment from '../../components/Payment/Payment'
import Spinner from '../../components/Spinner/Spinner'

if(window.location.search.indexOf('token') != -1) {
  var url = new URL(window.location.href);
  var token = url.searchParams.get('token');
  localStorage.setItem('token', token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  window.location.search = '';
} else if(localStorage.getItem('token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}

var autoUpgrade = false;
if(window.location.search.indexOf('autoUpgrade') != -1) {
  var url = new URL(window.location.href);
  var plan_id = url.searchParams.get('autoUpgrade');
  autoUpgrade = plan_id;
}

let asked_plan_id;

function UpgradePlan() {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({ firstName: '', lastName: ''});
  const [instance, setInstance] = useState({});
  const [address, setAddress] = useState({});
  const [methods, setMethods] = useState(null);
  const [subscription, setSubscription] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [coupon, setCoupon] = useState(null);
  const [isEditingBilling, setIsEditingBilling] = useState(false);
  const [plan_selected, setPlanSelected] = useState(null);


  const getSubscription = async (_user = user) => {
    const res = await axios.get(`${base_url}/users/${_user.id}/subscription`) // .then((res) => {
      if (res.data) { 
        const subscription = res.data;
        console.log(subscription);
        setSubscription(res.data);
        setMethods(subscription.payment_methods.data);
      }
      setLoading(false);
    // })
  }
  const getTransactions = (_user = user) => {
    axios.get(`${base_url}/users/${_user.id}/transactions`).then((res) => {
      if (res.data) { 
        setTransactions(res.data);
      }
    })
  }

  const getBilling = () => {
    axios.get(`${base_url}/users/${user.id}/billing`).then((res) => {
      if (res.data) { setAddress(res.data); }
    })
  }
  
  useEffect(() => {
    axios.get(base_url + "/users/me").then((res) => {
      const _user = res.data;
      if (_user) { 
        setUser(_user);
        axios.get(`${base_url}/users/${_user.id}/billing`).then((res) => {
          if (res.data) { setAddress(res.data); }
          const _address = res.data;
          if(!res.data.line1) {
            setIsEditingBilling(true)
          }
          axios.get(`${base_url}/users/${_user.id}/subscription`).then((res) => {
            if (res.data) { 
              const subscription = res.data;
              setSubscription(res.data);
              setMethods(subscription.payment_methods.data);
              if(subscription.payment_methods.data.length && autoUpgrade) {
                console.log(address, subscription.payment_methods.data.length)
                upgradePlan(autoUpgrade, _address, _user);
              } else {
                setLoading(false);
              }
            }
          })
        })
        axios.get(`${base_url}/users/${_user.id}/transactions`).then((res) => {
          if (res.data) { 
            console.log(res.data);
            setTransactions(res.data);
            setTimeout(() => {
              getTransactions(_user)
            }, 2000);
          }
        })
        axios.get(`${base_url}/plans?filter={"where":{"public":true}}`).then((res) => {
          if (res.data) {
            console.log(res.data);
            setPlans(res.data);
          }
        })
        axios.get(`${base_url}/coupon`).then((res) => {
          if (res.data) {
            console.log(res.data);
            setCoupon(res.data);
          }
        })
        axios.get(`${base_url}/users/${_user.id}/instance`).then((res) => {
          if (res.data) { 
            console.log(res.data);
            setInstance(res.data);
          }
        })
      }
    }).catch(() => {
      window.location.href = '/';
    })
  }, []);

  const onBillingSubmit = (e) => {
    e.preventDefault();
    let objInstance = {
      line1: e.target?.line1?.value,
      line2: e.target?.line2?.value,
      country: e.target?.country?.value,
      state: e.target?.state?.value,
      city: e.target?.city?.value,
      postal_code: e.target?.postal_code?.value,
      userId: user.id,
    }
    axios.patch(`${base_url}/users/${user.id}/billing`, objInstance).then((result) => {
      // alert("Billing updated successfully")
      setIsEditingBilling(false)
      getBilling()
    }).catch((error) => {
    });
  }

  const deletePaymentMethod = (id) => {
    setMethods([]);
    document.getElementById('show-modal').click();
    // axios.delete(`${base_url}/users/${user.id}/payment-method`).then((result) => {
    //   console.log(result);
    //   getSubscription();
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  const ask = (planId, _address = address, _user = user) => {
    if(methods && methods.length == 0) {
      upgradePlan(planId);
      return;
    }
    if(!_address.line1) {
      upgradePlan(planId);
      return;
    }
    asked_plan_id = planId;
    document.getElementById('show-modal-ask').click();
  }

  const yes = (planId, _address = address, _user = user) => {
    document.getElementById('show-modal-ask').click();
    asked_plan_id = null;
    upgradePlan(planId);
  }

  const upgradePlan = (planId, _address = address, _user = user) => {
    setPlanSelected(planId);
    if(methods && methods.length == 0) { 
      document.getElementById('show-modal').click();
      // document.getElementById("profile-tab").click();
      return;
    }
    if(!_address.line1) {
      document.getElementById('show-modal').click();
      // document.getElementById("profile-tab").click();
      return;
    }
    setLoading(true);
    axios.patch(`${base_url}/users/${_user.id}/subscription`, { planId: planId }).then(async (result) => {
      await getSubscription(_user);
      if(window.location.search) {
        window.location.search = '';
      }
      setTimeout(() => {
        if(document.getElementById("contact-tab")) {
          document.getElementById("contact-tab").click();
        }
        getTransactions(_user);
      }, 300);
    }).catch((error) => {
      console.log(error);
      setLoading(false)
    });
  }

  (function () {
    'use strict'
    var forms = document.querySelectorAll('.needs-validation')
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
  })()

  return ( <>
    { loading && <Spinner /> }
    { !loading && <>
      <div style={{ minHeight: "calc(100vh - 38px)"}}>
      <div className="topbar py-2 fixed-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4">
              <img src={logo} className="logo" />
            </div>
            <div className="col-8 text-end">
              <div className="align-items-center">
                <a href={ `https://${instance.domain}` } className="backlink">Dashboard</a> <span className="px-2">|</span>
                { user && user.id && <><span className="profile-name">{user.firstName} {user.lastName}</span> <span className="profileicon justify-content-center align-items-center">{ user.firstName[0] + user.lastName[0] }</span></> }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <h1 className="pb-4">TruPIM for small and medium eCommerce businesses</h1>
              <p style={{ fontSize: "18px", marginTop: "-18px" }}>Choose the right plan for your business. <b>No Credit Card required for a free trial!</b></p>
              {/* { subscription.planId && <div className='success-text' style={{ color: "#64B410", fontWeight: "500" }}>
                <br/>Renew On: { (new Date(subscription.subscription_end_date)).toDateString() }
              </div> } */}
            </div>
          </div>
        </div>
      </div>
      <div className="plans">
        <div className="section-decor"></div>
        <div className="container">
          <div className="row g-md-5" style={{ justifyContent: "center" }}>
            { subscription.recommendedPlan && <div className="col-md-3">
              <div className="pland-box text-center">
                { subscription.planId == subscription.recommendedPlan.id && <div className="current-plan">Current Plan</div> }
                
                {/* <h3>{'Trupim'}</h3> */}
                <div className="name pb-2">{subscription.recommendedPlan.name}</div>
                { !coupon && <div className="price">
                  $ {subscription.recommendedPlan.price} 
                  <span className="month-text">/Month</span>
                  {/* <small style={{fontSize: "small"}}>Monthly</small> */}
                </div>}
                {
                  coupon && <>
                    <div className="price">
                      $ {subscription.recommendedPlan.price} 
                      <span className="month-text">/Month</span>
                      {/* <small style={{fontSize: "small"}}>Monthly</small> */}
                    </div>
                    {/* <div>
                      <span className="month-text coupon-original-price">${subscription.recommendedPlan.price}/Month</span>
                    </div> */}
                  </>
                }
                <hr style={{ borderTop: "4px solid #BFBFBF", margin: "0 auto 15px", width: "50%" }} />
                <div className="list py-3 text-start">
                  <ul>
                    <li>{subscription.recommendedPlan.skus} SKUs</li>
                    <li>{subscription.recommendedPlan.users} Users</li>
                    <li>{subscription.recommendedPlan.languages} Languages</li>
                    <li>Unlimited channels</li>
                    <li>Product sheets</li>
                    <li>Digital asset management</li>
                    <li>Custom user roles</li>
                    <li>Email support</li>
                    <li>API access</li>
                    <li>Onboarding assistance</li>
                  </ul>
                </div>
                <div>
                  { subscription.planId == subscription.recommendedPlan.id && <>
                    <span className="d-inline-block renew-text">Auto-Renews: {(new Date(subscription.subscription_end_date)).toLocaleDateString()}</span>
                  </> }
                  {  subscription.planId != subscription.recommendedPlan.id && <button type="button" onClick={ () => ask(subscription.recommendedPlan.id) } className="btn btn-outline-secondary select-btn w-100">Upgrade</button>}
                  
                </div>
              </div>
            </div>}
            { plans && !subscription.recommendedPlan && plans.length && plans.map((plan, i) => {
              const _plan = stripe_plans.find(p => p.name === plan.name) || { name: '', items: []};
              if(subscription.planId == plans[0].id && i < 0) {
                return null;
              }
              if(subscription.planId == plans[1].id && i < 1) {
                return null;
              }
              if(subscription.planId == plans[2].id && i < 2) {
                return null;
              }
              if(subscription.planId == plans[3].id && i < 3) {
                return null;
              }
              return <div className="col-md-3" key={i}>
                <div className="pland-box text-center">
                  { subscription.planId == plan.id && <div className="current-plan">Current Plan</div> }
                  { subscription.planId != plan.id && coupon && <div className="current-plan" style={{ fontSize: "13px", fontWeight: 400 }}>${coupon.amount}/mo. Discount { coupon.duration ? `for First ${coupon.duration} Months` : '' }</div> }
                  {/* <h3>{'Trupim'}</h3> */}
                  <div className="name pb-2">{plan.name}</div>
                  { !coupon && <div className="price">
                    $ {plan.price} 
                    <span className="month-text">/Month</span>
                    {/* <small style={{fontSize: "small"}}>Monthly</small> */}
                  </div>}
                  {
                    coupon && <>
                      <div className="price">
                        $ {plan.price - coupon.amount} 
                        <span className="month-text">/Month</span>
                        {/* <small style={{fontSize: "small"}}>Monthly</small> */}
                      </div>
                      <div>
                        <span className="month-text coupon-original-price">${plan.price}/Month</span>
                      </div>
                    </>
                  }
                  <hr style={{ borderTop: "4px solid #BFBFBF", margin: "0 auto 15px", width: "50%" }} />
                  <div className="list py-3 text-start">
                    <ul>
                      { _plan.items.map(function (item, indexx) {
                        return <li key={indexx} className={item.indexOf('~') == 0 ? 'not_available' : ''}>
                          { item.indexOf('~') == 0 ? item.replace('~', '') : item}
                        </li>
                      }) }
                    </ul>
                  </div>
                  <div>
                    { subscription.planId == plan.id && <>
                      <span className="d-inline-block renew-text">Auto-Renews: {(new Date(subscription.subscription_end_date)).toLocaleDateString()}</span>
                    </> }
                    {  subscription.planId != plan.id && <button type="button" onClick={ () => ask(plan.id) } className="btn btn-outline-secondary select-btn w-100">Upgrade</button>}
                    
                  </div>
                </div>
              </div>
            }) }
          </div>
          <p className='text-center' style={{ fontSize: "16px", marginTop: "15px" }}>
            <b>*&nbsp; Add Ons</b><span style={{ fontWeight: "400"}}>: TruPIM Connectors for BigCommerce &amp; Shopify (discounted pricing from $99/mo-$199/mo).</span><br/><b>* Optional</b><span style={{ fontWeight: "400"}}>: Implementation Service Packs (for setup and launch).&nbsp;</span>
            <br/>
            <a href="mailto:sales@trupim.com">Contact sales</a> for more information.
          </p>
          { ((methods && methods.length > 0) || subscription.planId) && <div className="billing-sec pb-4 pt-4">
            <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Billing</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Payment</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Transaction
                  history</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="cancel-tab" data-bs-toggle="tab" data-bs-target="#cancel-tab-pane" type="button" role="tab" aria-controls="cancel-tab-pane" aria-selected="false">Cancel Account</button>
              </li>
            </ul>
            {/* <!--Billing--> */}
            <div className="tab-content pt-4" id="myTabContent">
              {user && user.id && !isEditingBilling && <div className="tab-pane p-3 p-md-4 fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                <div className="row">
                  <div className="col-8">
                    <h4 className="pb-2">Billing Address: </h4>
                    <p>{ address.line1 } {address.line2} <br />
                      {address.city}, {address.state} {address.country} {address.postal_code}</p>
                  </div>
                  <div className="col-4 text-end">
                    <button type="button" onClick={ () => setIsEditingBilling(true) } className="btn btn-outline-secondary">Edit</button>
                  </div>
                </div>
              </div>}
              {user && user.id && isEditingBilling && <div className="tab-pane p-3 p-md-4 fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                <div id="billing">
                  <form onSubmit={onBillingSubmit} className="px-4 py-3 rounded needs-validation" >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault01" className="form-label">Address1</label>
                          <input name='line1' type="text" defaultValue={ address.line1 } className="form-control" id="validationDefault01" required />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault02" className="form-label">Address2</label>
                          <input name='line2' type="text" defaultValue={ address.line2 } className="form-control" id="validationDefault02" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault03" className="form-label">Country</label>
                          <select name='country' defaultValue={ address.country || 'United States' } className="form-control" id="validationDefault03">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antartica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo">Congo, the Democratic Republic of the</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                            <option value="Croatia">Croatia (Hrvatska)</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="France Metropolitan">France, Metropolitan</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                            <option value="Holy See">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran (Islamic Republic of)</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                            <option value="Korea">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia, Federated States of</option>
                            <option value="Moldova">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                            <option value="Saint LUCIA">Saint LUCIA</option>
                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                            <option value="Span">Spain</option>
                            <option value="SriLanka">Sri Lanka</option>
                            <option value="St. Helena">St. Helena</option>
                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan, Province of China</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Viet Nam</option>
                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault04" className="form-label">State/Province</label>
                          <input name='state' type="text" defaultValue={ address.state } className="form-control" id="validationDefault04" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault05" className="form-label">City</label>
                          <input name='city' type="text" defaultValue={ address.city } className="form-control" id="validationDefault05" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault06" className="form-label">Zip code</label>
                          <input name='postal_code' type="text" defaultValue={ address.postal_code } className="form-control" id="validationDefault06" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary btn-md px-4">Save</button>
                    </div>
                  </form>
                </div>
              </div> }
              {/* <!--Payment--> */}
              <div className="tab-pane p-3 p-md-4 fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                { methods && methods.length > 0 && <>
                  <div className="card-type pb-3">
                      <a href="#" className="active"><img src={visa} /></a>
                      <a href="#"><img src={master}/></a>
                      <a href="#"><img src={amex}/></a>
                      <div style={{ float:"right" }}>
                        <button type="submit" onClick={ () => {deletePaymentMethod(methods[0].id)} } className="btn btn-black btn-md px-4">Replace Card</button>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-12">
                          <div className="mb-3">
                              <label className="form-label">Card Number</label>
                              <input type="text" className="form-control" defaultValue={ '**** **** **** ' + methods[0].card.last4} disabled/>
                          </div>
                      </div>
                      <div className="col-6">
                          <div className="mb-3">
                              <label className="form-label">Expiring Date</label>
                              <input type="text" className="form-control" defaultValue={ methods[0].card.exp_month +'/'+ methods[0].card.exp_year } disabled/>
                          </div>
                      </div>
                      <div className="col-6">
                          <div className="mb-3 ms-5">
                              <label className="form-label">Country</label>
                              <input type="text" className="form-control" defaultValue={ methods[0].card.country } disabled/>
                          </div>
                      </div>
                  </div>
                </> }
                { user && user.id && methods && !methods.length && <Payment methods={methods} user_id={user.id} /> }
              </div>
              {/* <!--Transaction--> */}
              <div className="tab-pane p-3 p-md-4 fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                <div className="" id="transaction">
                  <div className="table-responsive ">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Plan</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* { subscription.planId && <tr>
                          <td>{(new Date(subscription.subscription_end_date)).toLocaleString()}</td>
                          <td>{stripe_plans.find(plan => plan.id == subscription.planId).name}</td>
                          <td>{stripe_plans.find(plan => plan.id == subscription.planId).price} USD</td>
                          <td>{'Credit Card'}</td>
                          <td className={ 'success-text' }>{'Pending'}</td>
                        </tr> } */}
                        { transactions.map((transaction, i) => {
                          return <tr key={i}>
                            <td>{(new Date(transaction.timestamp)).toLocaleString()}</td>
                            <td>{plans.find(plan => plan.id == transaction.planId).name}</td>
                            <td>{transaction.amount/100} { transaction.currency.toUpperCase() }</td>
                            <td>{transaction.method}</td>
                            <td className={ transaction.status == 'payment_intent.succeeded' ? 'success-text' : 'failed-text' }>{transaction.status == 'payment_intent.succeeded' ? 'Success' : 'Failed'}</td>
                          </tr>
                        }) }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!--Cancel Acccont--> */}
              {user && user.id && <div className="tab-pane p-3 p-md-4 fade" id="cancel-tab-pane" role="tabpanel" aria-labelledby="cancel-tab" tabIndex={0}>
                <div className="row">
                  <p>Please contact <a href='mailto:support@trupim.com'>support@trupim.com</a> to cancel your account.</p>
                </div>
              </div>}
            </div>
          </div>}
        </div>
      </div>
      </div>
      <div className="footer px-4 py-2">
        <div className="row g-0 align-items-center">
          <div className="col">© 2023 TruPIM. All rights reserved.</div>
          <div className="col text-end">
            <a href="https://www.trupim.com/terms-and-conditions/" target={"_blank"} className="me-2">Terms of Use</a> <a href="https://www.trupim.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
          </div>
        </div>
      </div>
      <div id="show-modal-ask" data-bs-toggle="modal" data-bs-target="#askModal"></div>
      <div className="modal fade" id="askModal" tabIndex="-1" aria-labelledby="askModalLabel" aria-hidden="true" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center success-popup">
              <br/>
              <h5>Are you sure you want to upgrade?</h5><br/>
              <button type='button' className='btn btn-primary btn-md px-4' style={{ marginBottom: "10px"}} onClick={ () => { yes(asked_plan_id); } }>Yes</button><br/>
            </div>
          </div>
        </div>
      </div>
      <div id="show-modal" data-bs-toggle="modal" data-bs-target="#exampleModal"></div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center success-popup">
              <a className='close-popup' data-bs-toggle="modal"><i className='fa fa-times'></i></a>
              { user && user.id && isEditingBilling && <>
                <p className="pb-0" style={{marginBottom: 0, fontWeight: 600, textAlign: 'left' }}>Billing Address: </p>
                <form onSubmit={onBillingSubmit} className="rounded needs-validation" noValidate style={{ textAlign: "left"}}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="validationDefault01" className="form-label">Address1</label>
                          <input name='line1' type="text" defaultValue={ address.line1 } className="form-control" id="validationDefault01" required />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="validationDefault02" className="form-label">Address2</label>
                          <input name='line2' type="text" defaultValue={ address.line2 } className="form-control" id="validationDefault02" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault03" className="form-label">Country</label>
                          <select name='country' defaultValue={ address.country || 'United States' } className="form-control" id="validationDefault03">
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antartica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo">Congo, the Democratic Republic of the</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                            <option value="Croatia">Croatia (Hrvatska)</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="France Metropolitan">France, Metropolitan</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                            <option value="Holy See">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran (Islamic Republic of)</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                            <option value="Korea">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia, Federated States of</option>
                            <option value="Moldova">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                            <option value="Saint LUCIA">Saint LUCIA</option>
                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                            <option value="Span">Spain</option>
                            <option value="SriLanka">Sri Lanka</option>
                            <option value="St. Helena">St. Helena</option>
                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan, Province of China</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Viet Nam</option>
                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault04" className="form-label">State/Province</label>
                          <input name='state' type="text" defaultValue={ address.state } className="form-control" id="validationDefault04" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault05" className="form-label">City</label>
                          <input name='city' type="text" defaultValue={ address.city } className="form-control" id="validationDefault05" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="validationDefault06" className="form-label">Zip code</label>
                          <input name='postal_code' type="text" defaultValue={ address.postal_code } className="form-control" id="validationDefault06" />
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right"}}>
                      <button type="submit" className="btn btn-primary btn-md px-4">Next</button>
                    </div>
                  </form>
              </>}
              { user && user.id && !isEditingBilling && methods && !methods.length && <>
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                  <div className="row">
                    <div className="col-12" style={{ textAlign: 'left'}}>
                      <p className="pb-0" style={{marginBottom: 0, fontWeight: 600}}>Billing Address: </p>
                      <p>{ address.line1 } {address.line2} <br />
                        {address.city}, {address.state} {address.country} {address.postal_code}</p>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "0 0 10px" }}/>
                <p className="pb-0" style={{marginBottom: '5px', fontWeight: 600, textAlign: 'left' }}>Payment Details: </p>
                <Payment methods={methods} user_id={user.id} autoUpgrade={plan_selected} />
              </> }
            </div>
          </div>
        </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-u1OknCvxWvY5kfmNBILK2hRnQC3Pr17a+RTT6rIHI7NnikvbZlHgTPOOmMi466C8" crossOrigin="anonymous"></script>
    </> }
    </>
  )
}

export default UpgradePlan