import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import axios from "axios";
import { base_url } from "../../api";
import logo from "./images/logo.png";
import 'react-phone-input-2/lib/bootstrap.css';
import "./css/custom-styles.css";
import Spinner from '../../components/Spinner/Spinner'

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  // const [password, setPassword] = useState("");
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [personalEmail, setPersonalEmail] = useState(false);
  const [duplicateUsername, setDuplicateUsername] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const navigate = useNavigate();
  const standardEmail = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;

  const validation = () => {
    if (
      firstName === ""
      || lastName === ""
      || email === ""
      || phone === ""
      || username === ""
      // || password === "" ||
    ) {
      // alert("Please fill all the fields");
      if (!phone) {
        alert();
        document.getElementById("phoneBV").setAttribute("isvalid", "false")
      }
      return false;
    }
    if (!standardEmail.test(email)) {
      setPersonalEmail(true);
      return false;
    }
    let pattern = /^[a-zA-Z0-9 ]*$/gm;
    if(!pattern.test(username)) {
      setErrorUsername(true);
      return false;
    }
    // if (password.length < 8) {
    //   alert("Password must be atleast 8 characters long");
    //   return false;
    // }
    return true;
  };

  const recaptchaRef = React.createRef();

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validation()) {
      return;
    }
    const token = await recaptchaRef.current.executeAsync();
    console.log("recapcha response", token);


    if (token) {
      setLoading(true);
      axios.post(base_url + "/users",
        {
          firstName: firstName,
          lastName: lastName,
          username: username.split(" ").join("-").split(".").join("-"), // eagle-mro
          company: username, // eagle mro
          email: email,
          phone: phone,
          token: token,
          password: ''
          // password: password,
        }
      )
        .then((res) => {
          console.log(res.data);
          // alert("User Created Successfully. Please check your email to verify your email");
          // document.getElementById('show-modal').click();
          navigate('/registered');
        })
        .catch(async (err) => {
          setLoading(false);
          console.log(err.response.data);
          if (err.response && err.response.data && err.response.data.error && err.response.data.error && err.response.data.error.code == "ER_DUP_ENTRY") {
            if (err.response.data.error.message.indexOf('uniqueUsername') != -1) {
              setDuplicateUsername(true);
              // setUsername(username + '-' + (Math.floor( Math.random()*900 ) + 100))
              // await onSubmit(e);
            } else {
              setDuplicateEmail(true);
            }
          } else {
            alert(err.response.data.error.message)
          }
        });
    }
    return false;
  };

  const handleRedirect = () => {
    document.getElementById('show-modal').click();
    // navigate("/verify")
  }

  (function () {
    'use strict'

    var forms = document.querySelectorAll('.needs-validation')

    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
  })()

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && <div>
        <link rel="stylesheet" type="text/css" href="css/custom-styles.css" />
        <div className="signup">
          <div className="row g-0 align-items-center">
            <div className="col-12 col-md-6 signup-left d-flex align-items-center">
              <div className="p-3" style={{ width: "100%" }}>
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8">
                    <img src={logo} className="mb-5" />
                    <h1 className="pb-3" style={{ fontWeight: 600 }}>
                      Truly built for YOUR eCommerce
                    </h1>
                    <p style={{ marginTop: "-15px", fontSize: "18px" }}>
                      Powerful PIM to Scale Your Commerce & Data Management{" "}
                    </p>
                    <ul className="list-group">
                      {/* <li className="list-group-item signup-feature">
                        <i
                          className="signup-checkbox-icon fa-solid fa-square-check"
                        ></i>&nbsp;&nbsp;
                        14-day free trial
                      </li> */}
                      <li className="list-group-item signup-feature">
                        <i
                          className="signup-checkbox-icon fa-solid fa-square-check"
                        ></i>&nbsp;&nbsp;
                        Get your PIM ready in less than 3 minutes.
                      </li>
                      <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        Powerful PIM to scale your commerce & content operations.
                      </li>
                      <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        Truly built for YOUR eCommerce operations.
                      </li>
                      <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        No hosting and maintenance issues.
                      </li>
                      <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        Plug-and-play integration to BigCommerce and Shopify.
                      </li>
                      <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        Plug-and-play integrations to Widen & MediaValet DAM.
                      </li>
                      {/* <li className="list-group-item signup-feature">
                        <i className="signup-checkbox-icon fa-solid fa-square-check"></i>&nbsp;&nbsp;
                        No hosting and maintenance headache
                      </li>
                      <li className="list-group-item signup-feature">
                        <i
                          className="signup-checkbox-icon fa-solid fa-square-check"
                        ></i>&nbsp;&nbsp;
                        Free platform updates & support
                      </li>
                      <li className="list-group-item signup-feature">
                        <i
                          className="signup-checkbox-icon fa-solid fa-square-check"
                        ></i>&nbsp;&nbsp;
                        Out-of-the-box integration to BigCommerce and Shopify
                      </li>
                      <li className="list-group-item signup-feature">
                        <i
                          className="signup-checkbox-icon fa-solid fa-square-check"
                        ></i>&nbsp;&nbsp;
                        Accelerated eCommerce revenue
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="p-3">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8">
                    <h2 style={{ fontWeight: 600 }}>Sign up</h2>
                    <form onSubmit={onSubmit} className="row g-3" >
                      <div className="mb-3input-group has-validation">
                        <label className="form-label">First Name</label>
                        <input
                          autoFocus
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          aria-describedby="inputGroupPrepend"
                          type="text"
                          className="form-control"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter your first name.
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          className="form-control"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter your last name.
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Work Email</label>
                        <input
                          value={email}
                          onChange={(e) => {
                            setDuplicateEmail(false)
                            setPersonalEmail(false)
                            setEmail(e.target.value)
                          }}
                          type="email"
                          className="form-control"
                          required
                        />
                        <div className="invalid-feedback">
                          Please submit a valid email.
                        </div>
                        {duplicateEmail && <div style={{
                          "width": "100%",
                          "marginTop": "0.25rem",
                          "fontSize": ".875em",
                          "color": "#dc3545",
                        }}>
                          This email is already registered.
                        </div>}
                        {personalEmail && <div style={{
                          "width": "100%",
                          "marginTop": "0.25rem",
                          "fontSize": ".875em",
                          "color": "#dc3545",
                        }}>
                          Please enter work email. Personal Emails are not allowed
                        </div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Company Name</label>
                        <input
                          value={username}
                          onChange={(e) => {
                            setDuplicateUsername(false)
                            setUsername(e.target.value)
                          }}
                          type="text"
                          className="form-control"
                          required
                        />
                        <div className="invalid-feedback">
                          Please submit a valid company name.
                        </div>
                        {duplicateUsername && <div style={{
                          "width": "100%",
                          "marginTop": "0.25rem",
                          "fontSize": ".875em",
                          "color": "#dc3545",
                        }}>
                          This company name is already registered.
                        </div>}
                        {errorUsername && <div style={{
                          "width": "100%",
                          "marginTop": "0.25rem",
                          "fontSize": ".875em",
                          "color": "#dc3545",
                        }}>
                          This Company Name is invalid. Use only letter, number and single spaces.
                        </div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Phone Number</label>
                        <PhoneInput
                          inputStyle={{ width: '100%' }}
                          country={'us'}
                          value={phone}
                          onChange={phone => { setPhone(phone); }}
                          inputProps={{
                            id: 'phoneBV',
                            required: true
                          }}
                        />
                        <div className="invalid-feedback">
                          Please enter your phone number.
                        </div>
                      </div>
                      <div className="">
                        <label className="form-label">
                          <input type="checkbox" className="" required />&nbsp;&nbsp;
                          I agree to the <a href="https://www.trupim.com/terms-and-conditions/" target={'_blank'}>terms and conditions.</a>
                        </label>
                      </div>
                      {/* <div className="mb-3">
                      <label className="form-label">Password</label>
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">
                        Please submit a password.
                      </div>
                    </div> */}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY || "6Lf-03siAAAAAJ7EmkW8Me-_geX4t-PNuiWXzipY"}
                        onChange={onChange}
                      />
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg w-100"
                        >
                          SIGN UP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="show-modal" data-bs-toggle="modal" data-bs-target="#exampleModal"></div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center success-popup">
                <i className="fa-solid fa-circle-check icon"></i>
                <h3 className="pt-3">Your account was successfully created.</h3>
                <p>Please verify the email address to start using TruPIM.</p>
                {/* <button type="button" className="btn btn-primary btn-lg " onClick={handleRedirect}> Verify Email Address</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer px-4 py-2">
          <div className="row g-0 align-items-center">
            <div className="col">© 2023 TruPIM. All rights reserved.</div>
            <div className="col text-end">
              <a href="https://www.trupim.com/terms-and-conditions/" target={"_blank"} className="me-2">
                Terms of Use
              </a>
              <a href="https://www.trupim.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
            </div>
          </div>
        </div>


      </div>}</>
  );
}

export default Signup;
