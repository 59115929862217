let apiBaseURL;
let _stripe_publishable_key;
let _stripe_account_id;

if(window.location.hostname == 'localhost') {
    apiBaseURL = 'http://localhost:3000';
    _stripe_publishable_key = 'pk_test_IRk3IX5btg9HyOlPQFHCPq7F';
    _stripe_account_id = 'acct_1BLjfKBC4BDRWjqb';
} else {
    apiBaseURL = 'https://'+window.location.hostname.replace('webapp', 'pimapi');
    _stripe_publishable_key = 'pk_live_51LfPmhF8VTpSyQ5pSU91WLPFgRmMADizD4IEpiIMcmdkfho1Xrmbn1m1RhZHC4qLdSPjmbZiFE3fHQMPsTBme3s800rvbqXeBk';
    _stripe_account_id = 'acct_1LfPmhF8VTpSyQ5p';
}

export const stripe_account_id = _stripe_account_id;
export const stripe_publishable_key = _stripe_publishable_key;

export const base_url = apiBaseURL;

export const stripe_plans = [
    {
        name: 'Starter',
        items: [
            '3,000 SKUs',
            '2 Users',
            '2 Languages',
            'Unlimited channels',
            'Product sheets',
            'Digital asset management',
            'Custom user roles',
            'Email support',
            '~API access',
            '~Onboarding assistance',
        ]
    },
    {
        name: 'Basic',
        items: [
            '5,000 SKUs',
            '3 Users',
            '3 Languages',
            'Unlimited channels',
            'Product sheets',
            'Digital asset management',
            'Custom user roles',
            'Email support',
            '~API access',
            '~Onboarding assistance',
        ]
    },
    {
        name: 'Pro',
        items: [
            '10,000 SKUs',
            '5 Users',
            '5 Languages',
            'Unlimited channels',
            'Product sheets',
            'Digital asset management',
            'Custom user roles',
            'Email support',
            'API access',
            'Onboarding assistance',
        ]
    },
    {
        name: 'Enterprise',
        items: [
            '25,000 SKUs',
            '10 Users',
            '10 Languages',
            'Unlimited channels',
            'Product sheets',
            'Digital asset management',
            'Custom user roles',
            'Email support',
            'API access',
            'Onboarding assistance',
        ]
    },
]