import React, { useEffect } from "react";
import logo from "./media/logo.png";
import akeneo from "./media/1200px-Logo_akeneo.png";
import "./style.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { base_url } from "../../api";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const url  = new URL(window.location.href);
    const verification_key = url.searchParams.get('verification_key');
    if(verification_key) {
      console.log("Verification key: " + verification_key);

      const res = axios
        .get(base_url + "/users/verify/" + verification_key)
        .then((res) => {
          console.log(res.data);
          alert("Account Verified Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const validation = () => {
    if (email === "" || password === "") {
      // alert("Please fill all the fields");
      return false;
    }
    //email regex
    var emailRegex =
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Inside validation method");
    if (validation()) {
      console.log("Inside validation");
      const res = axios
        .post(base_url + "/users/login", {
          email: email,
          password: password,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  (function () {
    'use strict'

    var forms = document.querySelectorAll('.needs-validation')

    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
  })()

  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>

      <div className="Login d-flex flex-column align-items-center justify-content-center">
        <img src={logo} alt="" className="mb-3" width={300} />
        <form onSubmit={onSubmit} className="px-4 py-3 rounded needs-validation" noValidate>
          <span className="sign-in text-center d-block">
            Login to your account
          </span>{" "}
          <br />
          <label htmlFor="username" className="text-sm">
            Email
          </label>{" "}
          <br />
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control"
            required
          />
          <div className="invalid-feedback">
                    Please submit a valid email.
                  </div>
          <label htmlFor="username" className="text-sm">
            Password
          </label>{" "}
          <br />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="form-control"
            required
          />
          <div className="invalid-feedback">
                    Please submit a password.
                  </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <input
                type="checkbox"
                name="remember"
                id="remember"
                style={{ display: "inline-block" }}
              />
              <span className="remember-btn text-sm ml-2">Remember me</span>
            </div>
            <div className="forgot-text">
              <Link to="/forgot-password" className="forgot text-sm">
                  Forgot your password?
              </Link>
            </div>
          </div>
          <button type="submit" className="login-btn">
            LOGIN
          </button>
          <p className="account text-sm text-center">
            Don't have an Account?{" "}
            <Link to="/signup">
              <span style={{ color: "#f29e21" }}>Sign Up</span>
            </Link>
          </p>
        </form>
        <div className="powered d-flex mt-3">
          <div style={{ fontSize: "12px" }}>Powered by</div>
          <div>
            <img src={akeneo} alt="" className="pb-2" />
          </div>
        </div>
      </div>
      <div className="footer px-4 py-2">
        <div className="row g-0 align-items-center">
          <div className="col">© 2023 TruPIM. All rights reserved.</div>
          <div className="col text-end">
            <Link to="https://www.trupim.com/terms-and-conditions/" className="me-2">
              Terms of Use
            </Link>{" "}
            <Link to="https://www.trupim.com/privacy-policy/">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
