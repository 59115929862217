import React, { useEffect } from "react";
import logo from "./media/logo.png";
import akeneo from "./media/1200px-Logo_akeneo.png";
import "./style.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { base_url } from "../../api";


function Registered() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const url  = new URL(window.location.href);
    const verification_key = url.searchParams.get('verification_key');
    if(verification_key) {
      console.log("Verification key: " + verification_key);

      const res = axios
        .get(base_url + "/users/verify/" + verification_key)
        .then((res) => {
          console.log(res.data);
          alert("Account Verified Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const validation = () => {
    if (email === "" || password === "") {
      // alert("Please fill all the fields");
      return false;
    }
    //email regex
    var emailRegex =
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Inside validation method");
    if (validation()) {
      console.log("Inside validation");
      const res = axios
        .post(base_url + "/users/login", {
          email: email,
          password: password,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  (function () {
    'use strict'

    var forms = document.querySelectorAll('.needs-validation')

    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
  })()

  return (
    <div>
      <div>
        <div className="notification-screen d-flex flex-column align-items-center justify-content-center">
          <img src="/media/new-logo.png" alt="" className="mb-3" width={300} />
          <div className="px-4 py-4 rounded signup-notification">
            <h3 className="pt-3 detail-heading text-center">Your account was successfully created.</h3>
            <p>Please check your inbox and verify your email address to get started.</p>
          </div>
          <div className="powered d-flex mt-3">
            <div style={{fontSize: '12px'}}>
              Powered by
            </div>
            <div>
              <img src={akeneo} alt="" className="pb-2" />
            </div>
          </div>
        </div>
        <div className="footer px-4 py-2">
          <div className="row g-0 align-items-center">
            <div className="col">© 2023 TruPIM. All rights reserved.</div>
            <div className="col text-end">
              <a href="https://www.trupim.com/terms-and-conditions/" target={"_blank"} className="me-2">Terms of Use</a> <a href="https://www.trupim.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registered;
