import React from "react";
import { Link } from "react-router-dom";
function Homepage() {
  window.location.href = "/signup";
  return null;
  return (
    <div style={{ margin: "50px" }}>
      TODO : Put home page here
      <br/><br/>
      <Link to="/signup">
        <button>Sign up</button>
      </Link>&nbsp;&nbsp;&nbsp;&nbsp;
      {/* <Link to="/login">
        <button>Sign in</button>
      </Link> */}
    </div>
  );
}

export default Homepage;
