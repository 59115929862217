import React, { useState } from "react";
import axios from "axios";
import logo from "./media/logo.png";
import { base_url } from "../../api";
import "./custom-styles.css";


function VerifyEmail() {
  const [email, setEmail] = useState("");

  const validation = () => {
    if (email === "") {
      // alert("Please fill all the fields");
      return false;
    }
    //email regex
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      // alert("Please enter a valid email");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (validation()) {
      const res = await axios
        .post(base_url + "/users/reset-password/init", {
          email: email,
        })
        .then((res) => {
          console.log(res.data);
          alert("Email Sent Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  (function () {
    'use strict'

    var forms = document.querySelectorAll('.needs-validation')

    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
  })()
  return (
    <div>
      <div className="verify-user d-flex justify-content-center flex-column">
        <img src={logo} alt="" className="mx-auto mb-3" width={300} />
        <div className="col-12 col-md-6 d-block mx-auto">
          <div className="p-3">
            <div className="row justify-content-center">
              <div className="forgot-form col-12 col-md-8 px-4 py-4 rounded">
                <p className="mb-3 head-text text-center">Forgot Password? </p>
                <p className="mail-text mb-3">
                  Enter your email to receive instructions on how to reset your
                  password.
                </p>
                <form onSubmit={() => onSubmit()} className="row g-3 needs-validation" noValidate>

                <div className="mb-3">
                  <label className="form-label text-sm">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    required
                  />
                <div className="invalid-feedback">
                        Please submit a valid email.
                      </div>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg w-100"
                    >
                    SUBMIT
                  </button>
                </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer px-4 py-2">
        <div className="row g-0 align-items-center">
          <div className="col">© 2023 TruPIM. All rights reserved.</div>
          <div className="col text-end">
            <a href="https://www.trupim.com/terms-and-conditions/" target={"_blank"} className="me-2">
              Terms of Use
            </a>{" "}
            <a href="https://www.trupim.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
