import { Routes, Route, useLocation } from "react-router-dom";
import {useEffect, useState} from 'react';
import Homepage from "./pages/Homepage";
import Login from "./pages/Login/Login";
import Registered from "./pages/Login/Registered";
import Signup from "./pages/Signup/Signup";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/VerifyEmail/VerifyEmail";
import Spinner from "./components/Spinner/Spinner";
import "./App.css";
import UpgradePlan from "./pages/UpgradePlan/UpgradePlan";
import All from "./pages/All/All";

function App() {
  const [spinner, setSpinner] = useState(true)
  const location = useLocation();

  useEffect(() => {
      setSpinner(false)
  }, [location])

  return (<>
    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"></link>
    <div className="App">
      {spinner && <Spinner></Spinner>}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Spinner verify={true} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/registered" element={<Registered />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/upgrade-plan" element={<UpgradePlan />} />
        <Route path="/all" element={<All />} />
      </Routes>
    </div>
    </>
  );
}

export default App;
