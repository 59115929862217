import React, { useEffect, useState } from 'react';
import { base_url, stripe_plans } from "../../api";

import axios from 'axios';
import moment from 'moment';

import './style.css';

function All() {
  const [users, setUsers] = useState([]);
  const [control, setControl] = useState([]);
  const [restoring, setRestoring] = useState(null);
  const [backuping, setBackuping] = useState(null);
  const [reseting, setReseting] = useState(null);
  const [plans, setPlans] = useState([]);
  const [backups, setBackups] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const getUsers = () => {
    axios.get(`${base_url}/users`).then((res) => {
      if (res.data) { 
        setUsers(res.data.users);
        setControl(res.data.allowed);
      }
    })
  }

  const getPlans = () => {
    axios.get(`${base_url}/plans`).then((res) => {
      if (res.data) { 
        setPlans(res.data);
      }
    })
  }

  const getBackups = (user_id) => {
    console.log(user_id);
    axios.get(`${base_url}/users/${user_id}/backups`).then((res) => {
      var folders = [];
      if (res.data) {
        for (let i = 0; i < res.data.length; i++) {
          const folder = res.data[i];
          folders.push(folder);
        }
      }
      setBackups(folders);
    })
  }

  const showRestore = (user_id) => {
    document.getElementById('show-modal').click();
    setSelectedUser(user_id);
    getBackups(user_id);
  }

  const showRecommendPopup = (user_id) => {
    document.getElementById('show-modal-recommend').click();
    setSelectedUser(user_id);
  }

  const showCreatePlanPopup = () => {
    document.getElementById('show-modal-create-plan').click();
  }

  const resetData = (user_id) => {
    if(window.confirm('Are you sure you want to reset data')) {
      setReseting({
        user: users.find((user) => user.id === user_id),
        date: moment().format("lll")
      });
      axios.post(`${base_url}/users/${user_id}/reset-data`).then((res) => {
        setReseting(false);
      })
    }
  }

  const deleteAccount = (user_id) => {
    if(window.confirm('Are you sure you want to delete')) {
      axios.delete(`${base_url}/users/${user_id}`).then((res) => {
      })
    }
  }

  const doRestore = (user_id, backup) => {
    document.getElementById('show-modal').click();
    setRestoring({
      user: users.find((user) => user.id === user_id),
      date: moment(parseInt(backup)).fromNow()
    });
    axios.post(`${base_url}/users/${user_id}/restore`, {
      backup: backup
    }).then((res) => {
      setRestoring(false);
    })
  }

  const recommendPlan = (user_id, plan) => {
    document.getElementById('show-modal-recommend').click();
    console.log('recommend', plan, user_id);
    axios.patch(`${base_url}/users/${user_id}/subscription`, { recommendedPlan: plan.id }).then(async (result) => {
      console.log('recommend', result);
    });
  }

  const createPlan = (e) => {
    e.preventDefault();
    let objInstance = {
      name: "Custom",
      price: parseFloat(e.target?.price?.value),
      skus: parseFloat(e.target?.skus?.value),
      users: parseFloat(e.target?.users?.value),
      languages: parseFloat(e.target?.languages?.value),
    }
    axios.post(`${base_url}/plans`, objInstance).then((result) => {
      getPlans();
      document.getElementById('show-modal-create-plan').click();
    }).catch((error) => {
    });
  }

  const doBackup = (user_id) => {
    setBackuping({
      user: users.find((user) => user.id === user_id),
      date: moment().format("lll")
    });
    axios.post(`${base_url}/users/${user_id}/backups`).then((res) => {
      setBackuping(false);
    })
  }

  const fetchProcesses = () => {
    setInterval(() => {
      axios.get(`${base_url}/processes`).then((res) => {
        setProcesses(res.data);
      });
    }, 5000);
  }

  const copyLink = (user) => {
    var inp =document.createElement('input');
    document.body.appendChild(inp)
    inp.value = window.location.origin + '/verify?verification_key=' + user.verificationKey;
    inp.select();
    document.execCommand('copy',false);
    inp.remove();
  }

  useEffect(() => {
    getUsers();
    getPlans();
    fetchProcesses();
  }, []);

  return ( <>
    <div>
      {
        processes.map((process, i) => {
          if(process.type == 'restore') {
            return <h1 className='restoring'>{process.description}</h1>
          } else if (process.type == 'backup') {
            return <h1 className='restoring'>{process.description}</h1>
          } else if (process.type == 'delete') {
            return <h1 className='restoring' style={{ backgroundColor: "#f44336" }}>{process.description}</h1>
          }
        })
      }
      <ul className="nav nav-pills justify-content-center" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="users-tab" data-bs-toggle="tab" data-bs-target="#users-tab-pane" type="button" role="tab" aria-controls="users-tab-pane" aria-selected="false">Users</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="plans-tab" data-bs-toggle="tab" data-bs-target="#plans-tab-pane" type="button" role="tab" aria-controls="plans-tab-pane" aria-selected="false">Plans</button>
        </li>
      </ul>
      <div className="tab-content pt-4" id="myTabContent">
        <div className="tab-pane p-3 p-md-4 fade show active" id="users-tab-pane" role="tabpanel" aria-labelledby="users-tab" tabIndex="0">
          <div className="" id="users">
            <div className="table-responsive ">
              <table className="table">
                <thead>
                  <tr>
                    <th scope='col'>User Name</th>
                    <th scope='col'>User Email</th>
                    <th scope='col'>Tenant URL</th>
                    <th scope='col'>Registered At</th>
                    <th scope='col'>Plan Expiry</th>
                    <th scope='col'>Plan</th>
                    <th scope='col'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  { users.map((user, i) => {
                    var instance = user.instance;
                    return <tr key={i} className="" style={{ verticalAlign: "middle" }}>
                      <td>{ user.firstName + ' ' + user.lastName }</td>
                      <td>{user.email}</td>
                      <td><a href={"https://"+instance.domain} target="_blank">{instance.domain}</a></td>
                      <td>{ user.registeredAt && moment(user.registeredAt)?.format("lll") }</td>
                      <td>
                        { user.subscription && moment(user.subscription?.subscription_end_date)?.fromNow() }
                        { !user.subscription && <>
                          Not Verified
                          <button className='btn btn-primary' style={{ margin: '5px' }} onClick={ () => { copyLink(user); } }>Copy Link</button>
                        </> }
                      </td>
                      <td>{ plans.find(plan => plan.id === user.subscription?.planId)?.name }</td>
                      <td>
                        <button className='btn btn-primary' style={{ margin: '5px' }} onClick={ () => { showRestore(user.id) } }>Restore</button>
                        { control.delete_control && <button className='btn btn-primary' style={{ margin: '5px' }} onClick={ () => { resetData(user.id) } }>Reset Data</button> }
                        { control.delete_control && <button className='btn btn-primary' style={{ margin: '5px' }} onClick={ () => { deleteAccount(user.id) } }>Delete Account</button> }
                        <button className='btn btn-primary' style={{ margin: '5px' }} onClick={ () => { showRecommendPopup(user.id) } }>Assign Plan</button>
                      </td>
                    </tr>
                  }) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tab-pane p-3 p-md-4 fade" id="plans-tab-pane" role="tabpanel" aria-labelledby="plans-tab" tabIndex="0">
          <div className="" id="plans">
          <button className='btn btn-primary' onClick={ () => { showCreatePlanPopup() } }>Create Plan</button><br/><br/>
            <div className="table-responsive ">
              <table className="table">
                <thead>
                  <tr>
                    <th scope='col'>Plan Name</th>
                    <th scope='col'>Price</th>
                    <th scope='col'>SKUS</th>
                    <th scope='col'>Users</th>
                    <th scope='col'>Languages</th>
                  </tr>
                </thead>
                <tbody>
                  { plans.map((plan, i) => {
                    return <tr key={i} className="" style={{ verticalAlign: "middle" }}>
                      <td>{ plan.name }</td>
                      <td>$ { plan.price }</td>
                      <td>{ plan.skus }</td>
                      <td>{ plan.users }</td>
                      <td>{ plan.languages }</td>
                    </tr>
                  }) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="show-modal" data-bs-toggle="modal" data-bs-target="#exampleModal"></div>
    <div id="show-modal-recommend" data-bs-toggle="modal" data-bs-target="#recommendModal"></div>
    <div id="show-modal-create-plan" data-bs-toggle="modal" data-bs-target="#createPlanModal"></div>

    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center success-popup">
              Backups
            </div>
            <div className="table-responsive ">
              <table className="table" style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th>Backup Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  { backups.map((backup, i) => {
                    return <tr key={i}>
                      <td>{ moment(parseInt(backup))?.format("lll") }</td>
                      <td><button onClick={() => { doRestore(selectedUser, backup); }}>Restore</button></td>
                    </tr>
                  }) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    <div className="modal fade" id="recommendModal" tabIndex="-1" aria-labelledby="recommendModalLabel" aria-hidden="true"  data-bs-keyboard="false">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "50%" }}>
        <div className="modal-content">
          <div className="modal-body text-center success-popup">
            Plans
          </div>
          <div className="table-responsive ">
            <table className="table" style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th scope='col'>Plan Name</th>
                  <th scope='col'>Price</th>
                  <th scope='col'>SKUS</th>
                  <th scope='col'>Users</th>
                  <th scope='col'>Languages</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                { plans.map((plan, i) => {
                  if(plan.public) { return ; }
                  return <tr key={i} className="" style={{ verticalAlign: "middle" }}>
                    <td>{ plan.name }</td>
                    <td>$ { plan.price }</td>
                    <td>{ plan.skus }</td>
                    <td>{ plan.users }</td>
                    <td>{ plan.languages }</td>
                    <td><button className='btn btn-primary' onClick={() => { recommendPlan(selectedUser, plan); }}>Recommend</button></td>
                  </tr>
                }) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="createPlanModal" tabIndex="-1" aria-labelledby="createPlanModalLabel" aria-hidden="true"  data-bs-keyboard="false">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "50%" }}>
        <div className="modal-content">
          <div className="modal-body text-center success-popup">
            Create Plan
          </div>
          <div className="table-responsive ">
            <form onSubmit={ createPlan } className="px-4 py-3 rounded needs-validation" >
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="validationDefault051" className="form-label">Price</label>
                    <input name='price' type="number" className="form-control" id="validationDefault051" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="validationDefault052" className="form-label">SKUS</label>
                    <input name='skus' type="number" className="form-control" id="validationDefault052" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="validationDefault053" className="form-label">Users</label>
                    <input name='users' type="number" className="form-control" id="validationDefault053" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="validationDefault054" className="form-label">Languages</label>
                    <input name='languages' type="number" className="form-control" id="validationDefault054" required />
                  </div>
                </div>
                <button className='btn btn-primary' style={{ width: "auto", margin: "0 10px 0 auto" }}>Create</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default All