import React, { useEffect } from "react";
import "./custom-style.css";
import { useState } from "react";
import axios from "axios";
import { base_url } from "../../api";
import logo from "./media/logo.png";
import akeneo from "./media/1200px-Logo_akeneo.png";


function Spinner(props) {
  const [status, setStatus] = useState('Loading');
  const [instance, setInstance] = useState({});
  const [created, setCreated] = useState(false);

  const getStatus = (user_id) => {
    axios.get(base_url + "/users/" + user_id + '/instance').then((res) => {
      console.log(res.data);
      setStatus(res.data.status);
      if(res.data.status === 'success') {
        setCreated(true);
        setStatus('Instance created successfully and details are sent to your email address');
        if(window.location.hostname != 'localhost') {
          // window.location.href = 'http://' + res.data.domain;
        }
      }
      setInstance(res.data);
    }).catch((err) => {
      console.log(err);
    });
    setTimeout(() => { getStatus(user_id); }, 3000);
  }
  useEffect(() => {
    if(!props.verify) { return; }
    const url = new URL(window.location.href);
    const verification_key = url.searchParams.get('verification_key');
    if (verification_key) {
      axios.get(base_url + "/users/verify/" + verification_key).then((res) => {
        console.log(res.data);
        setStatus('Account Verified Successfully');
        getStatus(res.data.userId);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, []);

  if(!created) {
    return <>
      <div className="Loading d-flex flex-column align-items-center justify-content-center">
      <img src="/media/logo.png" alt="" className="mb-4" />
        <div className="loader rounded-circle" />
        <div className="status text-center mt-1 ms-3 fs-5">{status}<span className="status__dot">.</span><span className="status__dot">.</span><span className="status__dot">.</span></div>
      </div>
    </>
  } else {
    return <div>
    <div className="notification-screen d-flex flex-column align-items-center justify-content-center">
      <img src="/media/new-logo.png" alt="" className="mb-3" width={300} />
      <div className="px-4 py-4 rounded signup-notification">
        <h3 className="pt-3 mb-4 detail-heading text-center">Your Login Details</h3>
        {/* <p cla></p> */}
        <div className="login-detail w-50 text-center px-3 py-2 mx-auto rounded">
          <span>Username: </span> <span className="mx-2">admin</span>
        </div>
        <div className="login-detail w-50 text-center px-3 py-2 mx-auto rounded mt-2">
          <span>Password: </span> <span className="mx-3">admin</span>
        </div>
        {/* <button class="btn btn-primary btn-sm mt-3 py-2 px-4 d-block mx-auto w-75">Click here to login</button> */}
        <a href={'https://'+instance.domain} className="text-center d-block mt-4" style={{ fontWeight: 500 }}>Click here to login</a>
        <p className="mt-4 mb-0 credential-msg"><strong>Note:</strong> Your TruPIM Credentials are sent to your inbox for your reference.</p>
      </div>
      <div className="powered d-flex mt-3">
        <div style={{fontSize: '12px'}}>
          Powered by
        </div>
        <div>
          <img src={akeneo} alt="" className="pb-2" />
        </div>
      </div>
    </div>
    <div className="footer px-4 py-2">
      <div className="row g-0 align-items-center">
        <div className="col">© 2023 TruPIM. All rights reserved.</div>
        <div className="col text-end">
          <a href="https://www.trupim.com/terms-and-conditions/" target={"_blank"} className="me-2">Terms of Use</a> <a href="https://www.trupim.com/privacy-policy/" target={"_blank"}>Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
  }

  return (<div><>   
        {/* <div className="Login d-flex flex-column align-items-center justify-content-center">
          <img src={logo} alt="" className="mb-3" width={300} />
          <form className="px-4 py-3 rounded needs-validation" noValidate style={{ width: "80%" }}>
            <span className="sign-in text-center d-block">
              {status}
            </span>{" "}
            <br />
            <div style={{ textAlign: "center" }}>
              Please Login here <br/>
              <a style={{ color: 'blue' }} href={'http://'+instance.domain}>https://{instance.domain}</a><br/>
              <table style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                <tr>
                  <th>Username</th>
                  <th>Password</th>
                </tr>
                <tr>
                  <td> admin </td>
                  <td> admin </td>
                </tr>
              </table>
            </div>
          </form>
          <div className="powered d-flex mt-3">
            <div style={{ fontSize: "12px" }}>Powered by</div>
            <div>
              <img src={akeneo} alt="" className="pb-2" />
            </div>
          </div>
        </div> */}
      </>
    </div>
  );
}

export default Spinner;
