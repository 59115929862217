import React from "react";
import logo from "./media/logo.png";
import "./css/custom-styles.css";
import { useState } from "react";
import axios from "axios";
import { base_url } from "../../api";
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const validation = () => {
    if (email === "" || password === "" || confirm === "") {
      alert("Please fill all the fields");
      return false;
    }
    if (password.length < 8) {
      alert("Password must be atleast 8 characters long");
      return false;
    }
    if (password !== confirm) {
      alert("Passwords do not match");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (validation()) {
      const res = await axios
        .post(base_url + "/users/reset-password/finish", {
          resetKey: email,
          password: password,
          confirmPassword: confirm,
        })
        .then((res) => {
          console.log(res.data);
          alert("Password Reset Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="stylesheet" type="text/css" href="css/custom-styles.css" />
      <div className="forgot d-flex justify-content-center flex-column">
        <img src={logo} alt="" className="mx-auto mb-3" width={300} />
        <div className="col-12 col-md-6 d-block mx-auto">
          <div className="p-3">
            <div className="row justify-content-center">
              <div className="forgot-form col-12 col-md-8 border px-5 py-4 rounded">
                <p className="mb-3 head-text text-center">Forgot Password? </p>
                <div className="mb-3">
                  <label className="form-label text-sm">Username</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label text-sm">New password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label text-sm">
                    Confirm new password
                  </label>
                  <input
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}
                    type="password"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={() => {
                      onSubmit();
                    }}
                    className="btn btn-primary btn-lg w-100"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer px-4 py-2">
        <div className="row g-0 align-items-center">
          <div className="col">© 2023 TruPIM. All rights reserved.</div>
          <div className="col text-end">
            <a href="https://www.trupim.com/terms-and-conditions/" className="me-2">
              Terms of Use
            </a>{" "}
            <a href="https://www.trupim.com/privacy-policy/">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
